import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import "./CSS/Carousel.css";
import { storage } from "./misc/firebase-config";

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageRefs, setImageRefs] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [nextImage, setNextImage] = useState("");

  const photosAPICall = async () => {
    const imagesRef = ref(storage, "rivendell-images/");
    const response = await listAll(imagesRef);
    const imageRefs = response.items;
    setImageRefs(imageRefs);
    // Load the initial image and adjacent images
    loadImages(0, imageRefs);
  };

  const loadImages = async (index, imageRefs) => {
    if (imageRefs.length === 0) return;
    const lastIndex = imageRefs.length - 1;
    const shouldResetNextIndex = index === lastIndex;
    const shouldResetPrevIndex = index === 0;
    const nextIndex = shouldResetNextIndex ? 0 : index + 1;
    const prevIndex = shouldResetPrevIndex ? lastIndex : index - 1;

    const currentUrl = await getDownloadURL(imageRefs[index]);
    const nextUrl = await getDownloadURL(imageRefs[nextIndex]);
    const prevUrl = await getDownloadURL(imageRefs[prevIndex]);

    setCurrentImage(currentUrl);
    setNextImage(nextUrl);
    setPrevImage(prevUrl);
  };

  const prevSlide = () => {
    const lastIndex = imageRefs.length - 1;
    const shouldResetIndex = currentIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentIndex - 1;
    setCurrentIndex(index);
    loadImages(index, imageRefs);
  };

  const nextSlide = () => {
    const lastIndex = imageRefs.length - 1;
    const shouldResetIndex = currentIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentIndex + 1;
    setCurrentIndex(index);
    loadImages(index, imageRefs);
  };

  useEffect(() => {
    photosAPICall();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000);
    return () => clearInterval(intervalId);
  }, [currentIndex, imageRefs]);

  return (
    <div className="carousel">
      <div className={`slide active`}>
        <img src={currentImage} alt={`Rivendell${currentIndex + 1}`} />
      </div>
      <button className="prev" onClick={prevSlide}>
        &lt;
      </button>
      <button className="next" onClick={nextSlide}>
        &gt;
      </button>
    </div>
  );
}

export default Carousel;
