import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./CSS/Home.css";
import Timer from "./Timer";
import Carousel from "./Carousel";
import ButtonTiles from "./ButtonTiles";

function Home({ passwordCorrect, sidebar, setSidebar }) {
  const [areComponentsLoaded, setAreComponentsLoaded] = useState(false);

  useEffect(() => {
    // Simulate a delay for components loading
    const loadComponents = async () => {
      // Simulate async operations for other components if needed
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setAreComponentsLoaded(true);
    };
    loadComponents();
  }, []);

  return (
    <div className="home-main">
      <Header
        className="header-home-main"
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="home-body-wrapper">
        <ButtonTiles />
        <Timer />
        {areComponentsLoaded && <Carousel />}
      </div>
    </div>
  );
}

export default Home;
