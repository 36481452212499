// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdue2HpBnc-f1_ynD-PjM-CJQQe9nlktw",
  authDomain: "rivendell-website-30c99.firebaseapp.com",
  projectId: "rivendell-website-30c99",
  storageBucket: "rivendell-website-30c99.appspot.com",
  messagingSenderId: "655811065214",
  appId: "1:655811065214:web:b762b5f4aa90b4cfb9af2f",
  measurementId: "G-211KYV2XFG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);