import React from 'react';
import './CSS/MaintenanceMessage.css'; // Import the CSS file for styling

function MaintenancePage() {
  return (
    <div className="maintenance-container">
      <h2 className="maintenance-title">Oops. Were working on something here!</h2>
      <p className="maintenance-text">
        We are currently performing maintenance on our website. Please check back later.
      </p>
    </div>
  );
}

export default MaintenancePage;