import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import {SiBandsintown} from "react-icons/si"
import {FaTicketAlt} from "react-icons/fa"


export const navBarData = [
    {Title: "Home",
Path: "/home",
Icon: <AiIcons.AiTwotoneHome/>,
className: "nav-text"},
{Title: "Tickets",
Path: "/tickets",
Icon: <FaTicketAlt/>,
className: "nav-text"},
{Title: "Line-up",
Path: "/lineup",
Icon: <SiBandsintown/>,
className: "nav-text"},
{Title: "Photos",
Path: "/photos",
Icon: <AiIcons.AiFillCamera/>,
className: "nav-text"},
{Title: "About",
Path: "/about",
Icon: <AiIcons.AiFillInfoCircle/>,
className: "nav-text"}
]  