import React from 'react'
import { Link } from 'react-router-dom'
import { AiTwotoneMail, AiFillFacebook} from 'react-icons/ai';
import "./CSS/Footer.css"

export default function Footer() {

    const landingScreen = ()=>{
        return window.location.pathname === "/" ? true : false 
    }

  return (
      <div>
      {landingScreen() ? <></> :
      <div>
    
    <ul className='footer-main'>
    <li>
        <h3>RIVENDELL FESTIVAL</h3>
        <p>Escape reality and immerse yourself in a world of music, magic, and memories at Rivendell Festival. </p>
    </li>
    <li><h3>USEFUL LINKS</h3>
    <Link to="/tickets"><p>Tickets</p></Link>
    <Link to="/about"><p>About</p></Link>
    <Link to="/lineup"><p>Lineup</p></Link>
    <Link to="/photos"><p>Photos</p></Link>
    </li>

    <li>
        <h3>CONTACT US</h3>
        <Link to="/about">
        <div><AiTwotoneMail/> Email</div>
        </Link>

        <Link to="https://fb.me/e/5grWsNIfP">
        <div><AiFillFacebook/> Facebook</div>
        </Link>
 <div className='james-wilson'>Website built by <a href="https://www.jamesdavidwilson.co.uk/" target="_blank" rel="noopener noreferrer">James Wilson</a></div>
    </li>
</ul> 



 <div className='copyright'>Rivendell Festival ©{new Date().getFullYear()}</div> 
 </div>
}
  </div>

  )
  
}
