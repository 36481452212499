import React, { useState, useEffect } from "react";
import "./CSS/CountdownTimer.css";

function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());
  const [timePassed, setTimePassed] = useState(false);

  function getTimeLeft() {
    const futureDate = new Date("2024-07-05T12:00:00").getTime();
    const currentDate = new Date().getTime();
    if (futureDate < currentDate) return "passed";
    const timeLeft = futureDate - currentDate;
    return timeLeft;
  }

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(
        getTimeLeft() === "passed" ? setTimePassed(true) : getTimeLeft()
      );
    }, 1000);
  }, []);

  return (
    <div className="container">
      <div className="countdown-timer">
        <div className="timer">
          <div className="time-section">
            {timePassed ? (
              <p className="time-value">0</p>
            ) : (
              <p className="time-value">
                {Math.floor(timeLeft / (1000 * 60 * 60 * 24))}
              </p>
            )}
            <p className="time-label">Days</p>
          </div>
          <div className="time-section">
            {timePassed ? (
              <p className="time-value">0</p>
            ) : (
              <p className="time-value">
                {Math.floor((timeLeft / (1000 * 60 * 60)) % 24)}
              </p>
            )}
            <p className="time-label">Hours</p>
          </div>
          <div className="time-section">
            {timePassed ? (
              <p className="time-value">0</p>
            ) : (
              <p className="time-value">
                {Math.floor((timeLeft / (1000 * 60)) % 60)}
              </p>
            )}
            <p className="time-label">Minutes</p>
          </div>
          <div className="time-section">
            {timePassed ? (
              <p className="time-value">0</p>
            ) : (
              <p className="time-value">{Math.floor((timeLeft / 1000) % 60)}</p>
            )}
            <p className="time-label">Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
