
import './App.css';
import LandingPage from './Landing-page';
import './CSS/LandingPage.css'
import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom"
import Home from './Home'
import {useState} from 'react'
import Tickets from './Tickets';
import About from './About';
import Photos from './Photos';
import LineUp from './LineUp';
import Footer from './Footer';
import config from "./config.json"
import MaintenancePage from './MaintanancePage';



function App() {
  const [passwordCorrect, setPasswordCorrect] = useState(false)
  const [sidebar, setSidebar] = useState(false);

  return (config.maintanance ? (<MaintenancePage/>) : (
    <Router className='App wide-screen-padding loader'>
    <Routes>
      <Route path='/' element={<div className="home-page-background wide-screen-padding"> <Home passwordCorrect={passwordCorrect} sidebar={sidebar} setSidebar={setSidebar}/> </div>}/>
      <Route path='/home' element={<div className="home-page-background wide-screen-padding"> <Home passwordCorrect={passwordCorrect} sidebar={sidebar} setSidebar={setSidebar}/> </div>}/>
      <Route path='/tickets'  element={<div className="home-page-background wide-screen-padding"> <Tickets passwordCorrect={passwordCorrect} sidebar={sidebar} setSidebar={setSidebar}/> </div>}/>
      <Route path='/about'    element={<div className="home-page-background wide-screen-padding"> <About passwordCorrect={passwordCorrect} sidebar={sidebar} setSidebar={setSidebar}/> </div>}/>
      <Route path='/photos'  element={<div className="home-page-background wide-screen-padding"> <Photos passwordCorrect={passwordCorrect} sidebar={sidebar} setSidebar={setSidebar}/> </div>}/>
      <Route path='/lineup'  element={<div className="home-page-background wide-screen-padding"> <LineUp passwordCorrect={passwordCorrect} sidebar={sidebar} setSidebar={setSidebar}/> </div>}/>
    </Routes>
    <div className='home-page-background wide-screen-padding'><Footer/></div>
    </Router>
  ))
}

export default App;

