import React, { useState } from "react";
import "./CSS/EmailForm.css";

const EmailForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    body: ""
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const emailChecker = () =>{
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const isValidEmail = emailRegex.test(formData.email);
    console.log(isValidEmail, '<-----'); // true
  }

  const handleSubmit = e => {
    e.preventDefault();
    emailChecker()
    console.log(formData);
    // Add code to send the form data to a server
  };

  return (
    <form className="email-form"  action="https://formsubmit.co/james789111@gmail.com" method="POST">
      
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Your Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="body">Message:</label>
        <textarea
          id="body"
          name="body"
          value={formData.body}
          onChange={handleChange}
          required
        />
      </div>
      <button className="submit-button" type="submit">
        Submit
      </button>
    </form>
  );
};

export default EmailForm;