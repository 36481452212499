import React from 'react'
import tile1IMG from './misc/Photos/tile1.jpg'
import tile2IMG from './misc/Photos/tile2.jpg'
import tile3IMG from './misc/Photos/tile3.jpg'
import "./CSS/ButtonTiles.css"
import { useNavigate } from 'react-router-dom'

export default function ButtonTiles() {
    const navigate = useNavigate()


    const handleClick = (url) =>{
        navigate(url)
    }
  return (
    <div className='button-tiles-wrapper'>
        
            <div onClick={e=>handleClick("/lineup")} className='tile-wrapper'>
            <img src={tile1IMG}/>
            <div className='middle-text'>LINE UP</div>
            </div>
            <div onClick={(e)=>{handleClick('/photos')}} className='tile-wrapper'>
            <img src={tile2IMG}/>
            <div className='middle-text'>PHOTOS</div>
            </div>
            <div onClick={e=>handleClick("/about")} className='tile-wrapper'>
            <img src={tile3IMG}/>
            <div className='middle-text'>ABOUT</div>
            </div>
    </div>
  )
}
