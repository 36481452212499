import React from "react";
import Header from "./Header";
import EmailForm from "./EmailForm.jsx";
import './CSS/About.css'
import Accordion from "./Accordion";

const About = ({sidebar, setSidebar}) => {
  
  return (
    <>
        <Header sidebar={sidebar} setSidebar={setSidebar}/>
    <div className="about-main-wrapper">

       <div className="about-copy-wrapper"> 
      <h2>About Us</h2>
      <h3>If you have any further questions or concerns, please don't hesitate to reach out to us. The Rivendell team are always happy to assist and make sure you have all the information you need. Contact us through the form on our website, and we'll get back to you as soon as possible. </h3>
      </div>
    
      <EmailForm/>
      <Accordion/>
    </div>
    </>
  );
};

export default About