import React, { useEffect } from "react";

export default function SkiddleWidget() {
  useEffect(() => {
    skiddle();
  }, []);

  function skiddle() {
    var po = document.createElement("script");
    po.type = "text/javascript";
    po.async = true;
    po.src =
      "https://www.skiddle.com/infofeed/ticketpage.php?ilid=39313898;type=embedded";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(po, s);
  }

  return (
    <div>
      <div id="ticketbox_ph_39313898">
        <p>
          {" "}
          To buy tickets for this event please visit our events page:{" "}
          <a href="https://www.skiddle.com/whats-on/Workington/Rivendell/Rivendell-Secret-Festival-2025/39313898/">
            {" "}
            <strong> Rivendell Festival 2025 tickets from Skiddle </strong>{" "}
          </a>
          .
        </p>
      </div>{" "}
      <p
        style={{ "margin-top": "0", "font-size": "8pt", "line-height": "13px" }}
      ></p>
    </div>
  );
}
