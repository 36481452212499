import React from "react";
import Header from "./Header";
import "./CSS/LineUp.css";
export default function LineUp({ sidebar, setSidebar }) {
  return (
    <div>
      <Header sidebar={sidebar} setSidebar={setSidebar} />
      <div className="lineup-main">
        <h1>LINE-UP TO BE ANNOUNCED!</h1>
        <div className="artist-names-wrapper"></div>
      </div>
    </div>
  );
}
