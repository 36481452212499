import React from 'react'
import Carousel from './Carousel'
import Header from './Header'
import "./CSS/Photos.css"
import { Link } from 'react-router-dom'

export default function Photos ({sidebar, setSidebar}) {
  return (
    <>
        <Header sidebar={sidebar} setSidebar={setSidebar}/>
        <div className='photos-main-wrapper'>
        <Carousel/>
        <p>*If you have any photos you would like to share for the website, please <Link to="/about">contact us!</Link></p>
        </div>
        </>
  )
}
