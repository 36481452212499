import React, { useState, useEffect } from "react";
import "./CSS/Accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { ticketFaqs, aboutFaqs } from "./misc/utils/faqs";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [accordionQuestions, setAccordionQuestions] = useState([]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const handleFaqs = ()=>{
    switch (window.location.pathname) {
      case "/tickets": 
        setAccordionQuestions(ticketFaqs)
        break;
      case "/about":
        setAccordionQuestions(aboutFaqs)
    }
  }

  useEffect(() => {
    handleFaqs()
  }, [])
  

  

  return (
    <div className="main-accordion-wrapper">
      {accordionQuestions.map((faq, index) => (
        <div key={index} className="accordion-section">
          <h3 onClick={() => handleClick(index)}>
            {faq.question}
            <FontAwesomeIcon
              icon={faAngleDown}
              className={activeIndex === index ? "active" : ""}
            />
          </h3>
          {activeIndex === index && <p>{faq.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
