import "./App.css"
import "./CSS/Header.css"
import {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import DesktopHeader from "./DesktopHeader";
import Navbar from "./NavBar";
import {navBarData} from "./misc/navBarData"
import {Link} from "react-router-dom"


function Header ({sidebar, setSidebar}) {
    const [isMobile, setIsMobile] = useState(false)
    const [showMenu, setShowMenu] = useState(false);
    const [buttonShow, setButtonShow] = useState(true)
    const [logoShow, setLogoShow] = useState(true)
    let navigate = useNavigate()


    function isPhone() {
        const userAgent = window.navigator.userAgent;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const isSmallScreen = window.screen.width < 767;
        const hasTouchScreen = 'ontouchstart' in window;
        return (isMobile || (isSmallScreen && hasTouchScreen));
      }

      function handleClick(route) {
        navigate(route)
      }

      const handleClick2 = () =>{
        setLogoShow(!logoShow)
        setShowMenu(!showMenu) 
        if(window.location.pathname !== '/tickets'){
          showMenu ? setButtonShow(true) : setButtonShow(false)
        }
      }


    useEffect(() => {  
      setIsMobile(isPhone())
      console.log(window.location.pathname)
     if(window.location.pathname === '/tickets'){
      setButtonShow(false)
     }
      
    }, [isMobile])
    
    

    return (<div className="App-header">
      <Navbar sidebar={sidebar} setSidebar={setSidebar} />
        {isMobile ? <>
        {buttonShow ? <Link to="/tickets"><button className="mobile-ticket-button">Tickets</button></Link> : <></>}
        {logoShow ? <img className="rivendell-logo-header" src={require("./misc/Photos/rivendell-ying.png")}/> : <></>}
            <div className="burger-menu" onClick={() => {handleClick2()}}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {showMenu && (
        <ul className="mobile-nav-dropdown">
          {navBarData.map((item)=>{
            return (<Link to={item.Path} ><li className="mobile-nav-bar-wrapper">
              {item.Icon}
              <div className="mobile-nav-item">{item.Title}</div>
            </li>
            </Link>)
          })}
        </ul>
      )}
    </> : <DesktopHeader sidebar={sidebar} setSidebar={setSidebar}/>
        }
            </div>  );
}

export default Header ; 