import React from "react";
import "./CSS/desktopHeader.css";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";

export default function DesktopHeader({ setSidebar, sidebar }) {
  const navigate = useNavigate();

  function handleClick(route) {
    navigate(route);
  }

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <div className="desktop-header-main-wrapper">
      <link
        href="https://www.dafontfree.net/embed/YWJhZGktbXQtY29uZGVuc2VkLWV4dHJhLWJvbGQtcmVndWxhciZkYXRhLzgwNS9hLzE5NDY2NS9tYWxrYXZpYW4tZm9udDAudHRm"
        rel="stylesheet"
        type="text/css"
      />
      <div
        className="desktop-burger-menu"
        onClick={(e) => {
          showSidebar();
        }}
      >
        <p className="menu-title">MENU</p>
        <div className="menu-line-large"></div>
        <div className="menu-line-small"></div>
        <div className="menu-line-small"></div>
        <div className="menu-line-small"></div>
      </div>

      <h1 className="main-heading">
        <h1 className="rivendell-title">RIVENDELL</h1>
        <h2 className="secret-title">SECRET FESTIVAL</h2>
      </h1>

      <h3 className="date-title">5th-7th July 2024</h3>

      <button
        onClick={(e) => handleClick("/tickets")}
        className="tickets-button"
      >
        TICKETS
      </button>
    </div>
  );
}
