export const ticketFaqs = [
  {
    question: "Do the tickets include camping?",
    answer: "Yes, camping, caravans and campervans are included free of charge",
  },
  {
    question: "Can I bring my child?",
    answer:
      "Yes, We are a family friendly festival and you can bring your child under the age of 12 for free!",
  },

  {
    question: "What time does the festival start?",
    answer: "The festival starts at 2:00PM on Friday the 5th of July 2024",
  },
  {
    question: "Will there be tickets available on the gate?",
    answer:
      "If there are tickets still for sale on Skiddle at the time of the festival, we will offer tickets on the door (Cash payments welcome).",
  },
];

export const aboutFaqs = [
  {
    question: "Can I bring my child?",
    answer: "Yes, you can bring your child under the age of 12 for free!",
  },
  {
    question: "Will there be alcohol served?",
    answer:
      "Yes, we will stock a range of local ales, lagers, and spirits, all available at wallet-friendly prices.",
  },
  {
    question: "Can we bring our own alcohol?",
    answer:
      "Yes, feel free to bring your own alcohol, but if you can afford it please purchase a pint! Its how we keep our ticket prices low.",
  },
  {
    question: "When will the bands start?",
    answer: "The bands will play from 4PM on Friday and 12PM on the Saturday.",
  },
  {
    question: "What is the location of the festival?",
    answer:
      "The festival is located on the outskirts of Workington, Cumbria, in Stainburn. To get there, direct your sat-nav to the BP garage. Address: Stainburn Rd, Workington CA14 1SY. The gate behind the garage will be clearly signposted on your arrival.",
  },
];
