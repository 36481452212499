import React from 'react'
import SkiddleWidget from './SkiddleWidget'
import Header from './Header'
import './CSS/Tickets.css'
import Accordion from './Accordion'

export default function Tickets({passwordCorrect, sidebar, setSidebar}) {
    // let baseUrl = window.location.origin
    // console.log(passwordCorrect)
    //   if (passwordCorrect === false) {
    //         window.location.replace(`${baseUrl}/`);
    //         }

  return (
    <div className='tickets-main'>
        <Header sidebar={sidebar} setSidebar={setSidebar}/>
        <div className='tickets-main-wrapper'>
        <h1 className='heading-title'>Tickets:</h1>
        <SkiddleWidget/>
        <Accordion/>
        </div>
    </div>
  )
}
